<template>
  <div class="login">
    <Header :loginState="loginState" />
    <div class="input-bg">
      <!-- 完善资料组件 -->
      <!-- 实名认证组件 -->
      <perfect-data-model :dataTypeUrl="dataTypeUrl" :showModal="showModal"/>   
      <LoginFooter />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LoginFooter from "@/components/LoginFooter.vue";
import PerfectDataModel from '@/components/PerfectDataModel.vue';
export default {
  name: "PerfectData",
  components: {
    Header,
    LoginFooter,
    PerfectDataModel,
  },
  data(){
    return {
      loginState:2,
      dataTypeUrl:'',
      showModal:true,
    }
  },
  created() {
    const that = this;
    this.dataTypeUrl = that.$route.query.dataTypeUrl
  },
  methods: {

  },
};
</script>
<style>
@import url("../assets/css/public.css");
.perfect-data-modal{height:628px;margin-top: 70px;}
@media screen and (max-width: 768px) {
  .perfect-data-modal{height:100%;margin-top: 3rem;}
}  
</style>
